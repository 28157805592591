import { Module, Action, getModule, Mutation } from "vuex-module-decorators";
import store from "../index";
import { APIRun, Run, QueryTypes } from "../../types";
import router from "../../router";
import { ListingPageStore } from "@pasabi/ui-patterns";
import { $api } from "../../api/api";
import { RawLocation } from "vue-router";

const DEFAULT_RUNS_LIMIT = 500;

/**
 * Returns an identifier for a run using their dates, so we can have a unique key for
 * all the runs related with the same dates
 */
export const runDatesKey = (run: Run) =>
  `${run.runStartDate.toISOString()}:${run.runEndDate.toISOString()}`;

export const runName = ({ runEndDate, runStartDate }: { runEndDate: Date; runStartDate: Date }) => {
  const start = runStartDate.toLocaleString(undefined, {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    year: runStartDate.getFullYear() != new Date().getFullYear() ? "numeric" : undefined,
  });

  const end = runEndDate.toLocaleString(undefined, {
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
  });

  return `${start} - ${end}`;
};

export const parseAPIRun = (run: APIRun): Run => {
  const runStartDate = new Date(run.start_date);
  const runEndDate = new Date(run.end_date);
  const dateCreated = new Date(run.created_date);

  return {
    runId: run.display_id,
    query: run.query,
    runStartDate,
    runEndDate,
    dateCreated,
    runName: runName({
      runStartDate,
      runEndDate,
    }),
  };
};

const QUERIES_SORTING_ARRAY = [
  QueryTypes.Query_Spam,
  QueryTypes.Query_Scam,
  QueryTypes.Query_PositiveBiasCrossIPCombo,
  QueryTypes.Query_PositiveBiasCrossIPComboDemo,
  QueryTypes.Query_PositiveBiasLocalIPCAT,
  QueryTypes.Query_PositiveBiasLocalIPG2,
  QueryTypes.Query_NegativeBiasCrossIPCombo,
  QueryTypes.Query_SecondaryQuery,
  QueryTypes.Query_FlaggedDataCAT,
];

@Module({ dynamic: true, name: "Runs", store, namespaced: true })
class Runs extends ListingPageStore<APIRun> {
  _selectedRun: Run | null = null;
  _apiPath = "/runs";

  get api() {
    return $api;
  }

  get selectedRun(): Run | undefined {
    return this._selectedRun || undefined;
  }

  get selectedRunId(): string {
    return this._selectedRun?.runId || "";
  }

  get runs(): Run[] {
    return (this.items || [])
      .map(parseAPIRun)
      .filter((run) => {
        if (!process.env.VUE_APP_ALLOWED_QUERY_TYPES) {
          return true;
        }

        const allowedQueries = process.env.VUE_APP_ALLOWED_QUERY_TYPES.split(",");

        return allowedQueries.includes(run.query);
      })
      .sort((a, b) => {
        const indexA = QUERIES_SORTING_ARRAY.indexOf(a.query);
        const indexB = QUERIES_SORTING_ARRAY.indexOf(b.query);
        // if a is not found in order, give it a higher index value
        if (indexA === -1) return 1;
        // if b is not found in order, give a lower index value
        if (indexB === -1) return -1;
        // otherwise, sort based on index in order
        return indexA - indexB;
      });
  }

  get queryRunByDate() {
    return this.runs.reduce(
      (acc, cur) => {
        const datesKey = runDatesKey(cur);

        return {
          ...acc,
          [datesKey]: {
            name: runName(cur),
            runs: [...(acc[datesKey]?.runs || []), cur],
          },
        };
      },
      {} as Record<string, { runs: Run[]; name: string }>,
    );
  }

  get queryRunByDateAndQuery() {
    return (dateKey: string, query: Run["query"]): Run | undefined => {
      const runs = this.queryRunByDate[dateKey]?.runs || [];

      const index = runs.findIndex((b) => b.query == query);

      return runs[index];
    };
  }

  get runById() {
    return (runId: string) => {
      return this.runs.find((b) => b.runId == runId);
    };
  }

  @Mutation
  setSelectedRun(run: Run) {
    this._selectedRun = run;
  }

  @Action
  async updateSelectedRun({ runId, redirectTo }: { runId: string; redirectTo?: RawLocation }) {
    const run = this.runs.find((b) => b.runId === runId);
    if (run) {
      this.setSelectedRun(run);
      router.push(
        redirectTo || {
          params: { ...router.currentRoute.params, runId: runId },
        },
      );
    } else {
      throw new Error("Run that doesn't exist was selected");
    }
  }

  @Action
  async updateSelectedRunNoRedirect(value: string) {
    const run = this.runs.find((b) => b.runId === value);
    if (run) {
      this.setSelectedRun(run);
    } else {
      throw new Error("Run that doesn't exist was selected");
    }
  }

  get selectedRunIsMostRecent(): boolean {
    if (this._selectedRun === null) {
      return false;
    }
    return this.runIsMostRecent(this._selectedRun.runId);
  }

  get runIsMostRecent(): (runId: string) => boolean {
    return (runId): boolean => {
      const run = this.runs.find((b) => b.runId == runId);
      const [mostRecentRun] = [...this.runs].sort((a, b) =>
        b.dateCreated > a.dateCreated ? 1 : -1,
      );

      if (run) {
        return (
          mostRecentRun.runStartDate.toString() == run.runStartDate.toString() &&
          mostRecentRun?.runEndDate.toString() == run.runEndDate.toString()
        );
      }

      return false;
    };
  }

  @Action
  async fetchRuns(runId?: string) {
    await this.fetchItems({
      params: {
        limit:
          (process.env.VUE_APP_RUNS_LIMIT && parseInt(process.env.VUE_APP_RUNS_LIMIT)) ||
          DEFAULT_RUNS_LIMIT,
      },
    });

    const run = this.runs.find((b) => b.runId === runId);

    if (runId && run) {
      this.setSelectedRun(run);
    } else {
      router.push(`/${this.runs[0].runId}${router.currentRoute.fullPath}`);
    }
  }
}

export const RunsStore = getModule(Runs);
